@use 'styles/new-design/main.scss';

@mixin accessHighlight($color: var(--ds-info-500), $borderWidth: 3px) {
  border: $borderWidth solid $color !important;

  .mat-badge-content {
    background: $color !important;
    left: 50% !important;
    height: auto !important;
    line-height: 1.5 !important;
    width: auto !important;
    padding: 0 10px !important;
    transform: translate(-50%, -30%) !important;
    margin: 0 !important;
    font-size: calc(0.55 * var(--ds-spacing)) !important;
  }
}

.early-access-highlight {
  @include accessHighlight();
}

.privileged-access-highlight {
  @include accessHighlight(var(--ds-primary-500), 2px);
}

.no-subscript-field {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-table-sticky {
  position: sticky;

  &:first-child {
    left: 0;
  }

  &:last-child {
    right: 0;
  }
}

// different element sizes can lead to flickering in template
// we add a hidden elements to keep the size of the column consitend
.horizontal-spacing-helper {
  height: 0;
  overflow: hidden;
}
.vertical-spacing-helper {
  width: 0;
  overflow: hidden;
}

// ds related classes
.warning-expansion-panel mat-card {
  border: 1px solid var(--ds-warning-500);
}

// Use this as a temporary fix for the image sizing issue in the image component
// Keep until the issue is resolved from DS
.image {
  height: -webkit-fill-available;
  height: -moz-available;
  width: -webkit-fill-available;
  width: -moz-available;
}

ds-filter,
ds-filter-drawer {
  mat-error,
  mat-hint {
    position: absolute;
    top: -10px;
    left: 8px;
    background: var(--ds-neutral-contrast-900);
    padding: 0 calc(0.25 * var(--ds-spacing));

    // needed for ds-filter-input, which shows mat-hint allways
    &:empty {
      visibility: hidden;
    }
  }
}

// to enable sl-shared-file-select to span over the whole button
.mdc-button,
.mat-mdc-fab-base,
.mat-mdc-button {
  &.sl-file-select-button {
    .mdc-button__label:not([class*='default']) {
      position: unset;
    }
  }
}
